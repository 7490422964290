'use strict';

$(document).ready(function() {
    $('.navbar-collapse')
        .on('show.bs.collapse', function() {
            $(window).on('scroll', windowScrollCloseNavbar);
        }).on('hide.bs.collapse', function() {
            $(window).off('scroll', windowScrollCloseNavbar);
        });

    function windowScrollCloseNavbar() {
        $('.navbar-collapse').collapse('hide');
    }
});
